import { render, staticRenderFns } from "./Peers.vue?vue&type=template&id=7a86e1fd&scoped=true&"
import script from "./Peers.vue?vue&type=script&lang=js&"
export * from "./Peers.vue?vue&type=script&lang=js&"
import style0 from "./Peers.vue?vue&type=style&index=0&id=7a86e1fd&scoped=true&lang=css&"
import style1 from "./Peers.vue?vue&type=style&index=1&id=7a86e1fd&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../../../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "7a86e1fd",
  null
  
)

export default component.exports

/* vuetify-loader */
import installComponents from "!../../../../../node_modules/vuetify-loader/lib/runtime/installComponents.js"
import { VCard } from 'vuetify/lib/components/VCard';
import { VDataTable } from 'vuetify/lib/components/VDataTable';
installComponents(component, {VCard,VDataTable})
